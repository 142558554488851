import gql from 'graphql-tag';

export default gql`
query getAllReports {
  getReports {
    description
    name
    report_id
  }
}
`;
