var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _vm.allowedToCreateReports
        ? _c(
            "b-tabs",
            {
              key: _vm.number,
              attrs: {
                vertical: "",
                "nav-wrapper-class": "col-3",
                "nav-class": "mt-5"
              },
              on: { input: _vm.scrollToTop },
              scopedSlots: _vm._u(
                [
                  {
                    key: "tabs-start",
                    fn: function() {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "green-button-transparent mr-3 mb-3 border-0",
                            on: {
                              click: function($event) {
                                _vm.showCreateReportModal = true
                              }
                            }
                          },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "plus-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("buttons.createReport")) + " "
                            )
                          ],
                          1
                        ),
                        _vm.showCreateReportModal
                          ? _c("modal-create-new-report", {
                              staticClass: "d-inline-block mb-3",
                              on: {
                                "create-report": _vm.createReport,
                                cancel: function($event) {
                                  _vm.showCreateReportModal = false
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "empty",
                    fn: function() {
                      return [
                        _vm.allowedToCreateReports
                          ? _c("div", { staticClass: "mt-5 ml-5" }, [
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("reports.howToReportDesc")
                                  )
                                }
                              })
                            ])
                          : _c("div", { staticClass: "mt-5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("reports.emptyReports")) +
                                  " "
                              )
                            ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2134289535
              ),
              model: {
                value: _vm.tabIndex,
                callback: function($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex"
              }
            },
            _vm._l(_vm.reports, function(report, index) {
              return _c(
                "b-tab",
                {
                  key: index,
                  attrs: { lazy: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(" " + _vm._s(report.name) + " ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c("reports-list-view", {
                    staticClass: "mt-5",
                    attrs: {
                      user: _vm.user,
                      report: report,
                      "report-id": report.reportId,
                      tableIndex: index
                    },
                    on: {
                      "update-report": _vm.updateReportQuery,
                      "delete-report": _vm.deleteReportQuery
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _c("div", { staticClass: "pt-5" }, [
            !_vm.$apollo.loading
              ? _c("div", [
                  _c("p", {
                    staticClass: "text-center",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("reports.notAllowed"))
                    }
                  })
                ])
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }