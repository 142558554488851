<template>
  <!-- Reports page -->
  <b-container>
    <b-tabs
        v-if="allowedToCreateReports"
        vertical
        nav-wrapper-class="col-3"
        nav-class="mt-5"
        :key="number"
        v-model="tabIndex"
        @input="scrollToTop"
    >
      <template #tabs-start>
        <b-button
          @click="showCreateReportModal = true"
          class="green-button-transparent mr-3 mb-3 border-0">
          <b-icon
            icon="plus-circle-fill"
            class="mr-2 top--3"
            aria-hidden="true"
            scale="1"
          ></b-icon>
          {{ $t('buttons.createReport') }}
        </b-button>
        <modal-create-new-report
            v-if="showCreateReportModal"
            class="d-inline-block mb-3"
            @create-report="createReport"
            @cancel="showCreateReportModal = false"
        ></modal-create-new-report>
      </template>
      <template #empty>
        <div class="mt-5 ml-5" v-if="allowedToCreateReports">
          <p v-html="$t('reports.howToReportDesc')"></p>
        </div>
        <div class="mt-5" v-else>
          {{ $t('reports.emptyReports') }}
        </div>
      </template>
      <b-tab v-for="(report, index) in reports" :key="index" lazy>
        <template v-slot:title>
          {{ report.name }}
        </template>
        <reports-list-view
            class="mt-5"
            :user="user"
            :report="report"
            :report-id="report.reportId"
            :tableIndex="index"
            @update-report="updateReportQuery"
            @delete-report="deleteReportQuery"
        ></reports-list-view>
      </b-tab>
    </b-tabs>
    <div v-else class="pt-5">
      <div v-if="!$apollo.loading">
        <p class="text-center" v-html="$t('reports.notAllowed')"></p>
      </div>
    </div>
  </b-container>
</template>

<script>
import CreateReport from '@/graphQlQueries/mutations/createReport';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import SetRunBreadcrumbQueryInCache from '@/graphQlQueries/mutations/setRunBreadcrumbQueryInCache';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import UserAtLeastManagerInOneGroup from '@/graphQlQueries/queries/accessCheckForManagementAreas';

import GetAllReports from '@/graphQlQueries/queries/getAllReports';
import GetUserByEmail from '@/graphQlQueries/queries/getUserByEmail';
import Auth from '@aws-amplify/auth';

export default {
  name: 'Group',
  components: {
    ModalCreateNewReport: () => import('@/components/modals/ModalCreateNewReport.vue'),
    ReportsListView: () => import('@/components/settings/reports/ReportsListView.vue'),
  },
  data: () => ({
    pageType: 'reports-settings',
    reports: [],
    number: 342,
    tabIndex: 0,
    allowedToCreateReports: false,
    showCreateReportModal: false,
    newOrChangedReportId: null,
    deletedReportId: null,
    userId: null,
    user: null,
  }),
  async created() {
    const currentSession = await Auth.currentSession();
    const idTokenPayload = JSON.parse(currentSession.getIdToken().payload['https://hasura.io/jwt/claims']);
    this.userId = (idTokenPayload['x-hasura-user-id'])
      ? Math.floor(idTokenPayload['x-hasura-user-id'])
      : null;
    if (this.userId) {
      // check if the user is admin
      if (idTokenPayload['x-hasura-allowed-roles'].includes('admin')) {
        this.$currentUserRole = 'admin';
      } else {
        this.$currentUserRole = 'user';
      }
    }
    await this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
    this.userEmail = Auth.user.attributes.email;
    const {
      data: {
        users: [user],
      },
    } = await this.$apollo.query({
      query: GetUserByEmail,
      variables: {
        email: this.userEmail,
      },
    });
    this.user = {
      userId: this.userId,
      name: user.full_name || null,
      email: Auth.user.attributes.email,
      isSysAdmin: user.sys_admin || false,
    };
    await this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: 'reports-settings',
      },
    });
    await this.$apollo.mutate({
      mutation: SetRunBreadcrumbQueryInCache,
      variables: {
        runQuery: true,
      },
    });
    await this.$apollo.mutate({
      mutation: SetShowHeaderInCache,
      variables: {
        show: true,
      },
    });
  },
  apollo: {
    allowedToCreateReports: {
      query: UserAtLeastManagerInOneGroup,
      update(data) {
        return data.checkAccessToManagementAreas;
      },
    },
    reports: {
      query: GetAllReports,
      update(data) {
        console.log('reports data', data);
        const reportObjects = [];
        if (data.getReports) {
          data.getReports.forEach((reportItem) => {
            const element = {
              reportId: reportItem.report_id,
              name: reportItem.name,
              description: reportItem.description,
            };
            reportObjects.push(element);
          });
        }

        reportObjects.sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          // eslint-disable-next-line no-nested-ternary
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        if (this.newOrChangedReportId !== null) {
          const newOrChangedReportIndex = reportObjects.findIndex(
            (report) => report.reportId === this.newOrChangedReportId,
          );
          if (newOrChangedReportIndex !== -1) {
            // this is a workaround to update the active tab on create a new report
            // to respect the update cycle of the b-tabs
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.$nextTick(() => {
                  this.$nextTick(() => {
                    requestAnimationFrame(() => {
                      this.tabIndex = newOrChangedReportIndex;
                      this.newOrChangedReportId = null;
                    });
                  });
                });
              });
            });
          }
        }

        if (this.deletedTeamId !== null) {
          this.tabIndex = 0;
          this.deletedTeamId = null;
        }

        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
        return reportObjects;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async createReport(reportObject) {
      await this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      const settings = {
        schedule: 'monthly',
      };
      try {
        // insert a new report!
        const {
          data: {
            addReport: {
              report_id,
            },
          },
        } = await this.$apollo.mutate({
          mutation: CreateReport,
          variables: {
            obj: {
              name: reportObject.name,
              description: reportObject.desc,
              settings: JSON.stringify(settings),
            },
          },
        });
        this.newOrChangedReportId = report_id;
        this.number += 1;
        this.showCreateReportModal = false;
        await this.$apollo.queries.reports.refresh();
      } catch (ex) {
        console.error(ex);
        this.showCreateReportModal = false;
        await this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
      }
    },
    deleteReportQuery() {
      this.tabIndex = 0;
      this.$apollo.queries.reports.refresh();
    },
    updateReportQuery(reportId) {
      this.newOrChangedReportId = reportId;
      this.$apollo.queries.reports.refresh();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}
</style>
